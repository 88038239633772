@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  font-family: 'Ubuntu', sans-serif;
  background-color: black;
  color: white;
}

.primary-color {
  color: #50ffc8;
}

.secondary-color {
  color: #ff0b56;
}

/* Nav Bar */

.vertical-navbar {
  border-right: 1px solid #50ffc8;
  height: 100vh;
}

.vertical-navbar a {
  color: #50ffc8;
  padding: 0.6rem 0rem;
  margin: 0.3rem;
}

.nav-link:focus, .nav-link:hover, .active {
  background-color: #83ffd8;
  border-radius: 3px;
  color: black !important;
}

/* Content Area */

.main-content {
  height: 100vh;
  overflow-y: scroll;
  background-color: rgb(22, 22, 22);
}

/* Home Hero Introduction */

.home-title, .about-me-container, .cv-container, .projects-container, .contact-container {
  min-height: 100vh;
  padding: 7rem 5rem;
  text-align: start;
}

.introduction {
  padding-left: 3rem;
  align-items: center;
}

.introduction h1 {
  line-height: 1;
  font-weight: 700;
}

.introduction p {
  font-weight: 400;
}

.about-me-container h1, .cv-container h1, .projects-container h1, .contact-container h1 {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.about-me-container h1:after, .cv-container h1:after, .projects-container h1:after, .contact-container h1:after {
  display: block;
  position: relative;
  content: '';
  width: 96%;
  margin-top: 0.2rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  height: 0.3rem;
  background-color: rgb(202, 202, 202);
}

/* About Me */
/* .image-text-container {
  display: flex;
} */

.about-text {
  /* width: 140%; */
}

@media only screen and (min-width: 1000px) {
  .image-text-container {
    display: flex;
  }
}

/* .about-images {
  display: flex;
}

.about-images img{
  object-fit: cover;
} */


/* .grow {
  flex-grow: 2;
} */

.about-me p{
  font-size: 1.2rem;
}

/* Projects */

.project-title {
  padding-bottom: 1rem;
}

.projects strong{
  color: #50ffc8;
}

.projects a  {
  color: #ff0b56;
  text-decoration: none;
}

.projects a:hover, a:focus  {
  text-decoration: none;
  color: #ee0c50cb;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.project-image {
  object-fit: cover;
  width: 90%;
}

.tech-stack-list {
  display: flex;
  flex-wrap: wrap;
}

.tech-stack-item {
  padding: 0.5rem 2rem; 
  list-style-type: none;
  color: #fff;
  text-shadow: 0 0 10px #50ffc8;
}

.epuck {
  object-fit: cover;
  width: auto;
  height: 8rem;
}

/* CV */

.cv h2 {
  font-weight: 400;
}

.cv-element-header {
  display: flex;
}

.cv-element-container {
  max-width: 800px;
}

.blockquote-footer {
  margin-top: 0 !important;
  margin-left: 0.6rem;
}

.cv-description {
  margin-left: 2rem;

}

/* Contact me */

.contact-me input:focus, .contact-me textarea:focus {
  border-color: #ff0b56;
  box-shadow: none;
}

.contact-me button {
  background-color: #50ffc8;
  border-color: #50ffc8;
  color: black;
}

.contact-me button:active, .contact-me button:focus, .contact-me button:active:focus, .contact-me button:hover {
  box-shadow: none;
  background-color: #ff0b56;
  border-color: #ff0b56;
}

.contact-thanks {
  margin-bottom: 9rem;
}
